.chat .peaople-search{
    z-index: 2;
    position: fixed;
    width: 100%;
    background: #fdf03ed6;
}
.chat .people-chat{
    border-radius: 50px 50px 0px 0px;
    background: white;
    padding: 125px 0px;
    display: flex;
    justify-content: center;
}
.chat-text{
    bottom: 135px;
    width: 100%;
}
.chat-text .to-msg, .chat-text .from-msg{
    padding: 20px;
    background: whitesmoke;
    margin: 10px;
    border-radius: 50px;
    position: relative;
}
.chat-heading{
    padding: 20px;
    padding-left: 75px;
    font-size: 30px;
}
.messages{
    background: white;
    position: absolute;
    bottom: 0px;
    width: 100%;
    position: fixed;
}
.send-msg{
    text-align: center;
    display: flex;
    justify-content: center;
    position: relative;
}
.send-msg input{
    width: 100%;
    margin: 30px;
    border: none;
    border-radius: 40px;
    padding: 20px 70px 20px 30px;
    background: #ececec8a;
    outline: none;
    font-size: 20px;
    color: gray;
}
.send-btn{
    position: absolute;
    right: 43px;
    bottom: 44px;
    border-radius: 50px;
    box-shadow: 0px 0px 5px 0px grey;
    width: 23px;
    height: 23px;
    padding: 6px;
}
.send-btn img{
    width: 100%;
    cursor: pointer;
}
.chat-img{
    width: 35px;
    position: fixed;
    top: 20px;
    left: 78px;

}
.chat-img img{
    width: 100%;
    border-radius: 50px;
}
.chat-name{
    padding-left: 46px;
}