.msg-div{
    display: inline-block;
    width: 100%;
}
.to-msg{
    float: right;
}
.from-msg{
    float: left;
}
.chat-text .from-msg span, .chat-text .to-msg span{
    position: absolute;
    bottom: -20px;
    color: #d2d0d0;
}
.chat-text .from-msg span{
    left: 22px;    
}
.chat-text .to-msg span{
    right: 22px;
}