body {
  font-family: "Goudy Bookletter 1911", sans-serif;
  margin: 0;
  height: 100%;
}
@media (min-width:700px){
  .App{
    justify-content: center;
    display: flex;
  }
  .App .peaople-search, .App .chat, .App .messages, .App .home-page{
    width: 50% !important;
  }
  .back-btn {
    left: 26% !important;
  }
  .chat-img{
    left: 31% !important;
  }
}